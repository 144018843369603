<template>
  <div class="navbar-container d-flex content align-items">
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <!-- <dark-Toggler class="d-none d-lg-block" /> -->
      <div v-if="!is_mobilesize" class="mr-1">
        <span class="pointer pl-2 span_class" @click="linked('list-coin')"
          ><small class="font-weight-bold"><b>LIST COIN</b> </small></span
        >
        <span
          class="pointer pl-1 span_class font-weight-bold"
          @click="linked('contact-us')"
          ><small class="font-weight-bold"><b>CONTACT US</b></small></span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mixinList } from "@/mixins/mixinList";
import { $themeConfig } from "@themeConfig";
import {} from "bootstrap-vue";
// import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
// import Ripple from "vue-ripple-directive";
export default {
  mixins: [mixinList],
  components: {
    // DarkToggler,
  },
  setup() {
    // App Name
    const { appLogoImage, appName } = $themeConfig.app;
    return {
      appLogoImage,
      appName,
    };
  },
  methods: {
    linked: function (e) {
      this.$router.push({ path: "/" + e });
    },
  },
};
</script>
<style scoped>
.mr-1, .mx-1 {
    margin-right: 1rem !important;
    margin-left: 70%;
}
.pointer {
  cursor: pointer;
}
.span_class {
  font-family: "Montserrat", Helvetica, Arial, serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.45;
  /* color: #5e5873; */
}
img {
  border-style: none;
  width: 29px;
  margin-top: -20px;
  margin-left: 100px;
}
.height-change {
  height: 200px;
}
.b-link-class {
  color: #b4b7bd;
}
@media (max-width: 1024px) {
  .header-navbar .navbar-nav {
    margin-bottom: 2rem;
  }
}
.loged-social-class {
  margin-right: 150px !important;
}
.horizontal-menu .navbar.header-navbar .navbar-container {
  padding: 0.8rem 2rem;
  margin: 10px;
}
</style>
