export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Add Coin',
    route: 'add-coin',
    icon: 'TrendingUpIcon',
  },
  {
    title: 'Promote',
    route: 'promote',
    icon: 'ChevronsUpIcon',
  },
  {
    title: 'Audit',
    route: 'audit-coin',
    icon: 'CheckCircleIcon',
  },
  {
    title: 'Services we offer',
    route: 'services-we-offer',
    icon: 'AwardIcon',
  },
  {
    title: 'Presale',
    route: 'presale',
    icon: 'AirplayIcon',
  },
  // {
  //   title: 'Rugscanner',
  //   route: 'https://www.rugscan.finance/',
  //   icon: 'UserPlusIcon',
  // },
  {
    title: 'Login',
    route: 'login',
    icon: 'LogInIcon',
  },
  {
    title: 'Register',
    route: 'register',
    icon: 'UserPlusIcon',
  }
]
